import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import TagListing from "../components/Language/TagListing";

export default function SyntaxTemplate({ pageContext, data }) {
  const { syntax } = pageContext;
  const languageEdges = data.allLanguagesJson.edges;
  return (
    <Layout>
      <Helmet
        title={`Languages with "${syntax}" syntax| ${config.siteTitle}`}
      />
      <TagListing
        tag={syntax}
        category="syntax"
        allLanguagesJson={data.allLanguagesJson}
        languageEdges={languageEdges}
        description="Certain languages are more intuitive even to people reading code for the first time. For experienced coders, languages that follow common patterns are easier to pick up. Other languages have extensive types or weird syntax for no apparent reason."
      />
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query SyntaxPage($syntax: String) {
    allLanguagesJson(
      limit: 1000
      sort: { fields: [syntax___value], order: DESC }
      filter: { syntax: { value: { eq: $syntax } } }
    ) {
      totalCount
      edges {
        node {
          slug
          name
          description
          used_for
          getting_help {
            value
          }
          pay {
            value
          }
          barrier_to_entry {
            value
          }
          demand {
            value
          }
        }
      }
    }
  }
`;
